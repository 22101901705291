import React from 'react';
import { graphql } from 'gatsby';

import styled, { ThemeProvider } from 'styled-components';
import Img from 'gatsby-image';
import { GlobalStyle } from '../components/globalStyle';
import theme from '../theme';
import SEO from '../components/seo';
import { Stack, H, P, Center, Cluster } from '../components/system';
import { Container } from '../components/layoutComponents';
import SignupForm from '../components/SignupForm';
import Footer from '../components/footer';
import { InternalLink } from '../components/Links';
import { breakpoint } from '../constants';
import beerFinderLogo from '../images/logo--name.svg';
import '../components/layout.css';

export const pageQuery = graphql`
  query MyQuery {
    allMdx(sort: { order: DESC, fields: frontmatter___date }) {
      edges {
        node {
          frontmatter {
            author
            date(formatString: "MMMM Do, YYYY")
            path
            title
            metaDescription
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;

function LayoutPost({ data }) {
  const {
    allMdx: { edges },
  } = data;

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <SEO
        title="Craft Beer Blog"
        path="/blog/"
        description="A blog about the Ontario craft beer scene"
      />
      <Nav>
        <Container>
          <Stack>
            <Cluster
              css={`
                justify-content: space-between;
                align-items: center;
              `}
            >
              <InternalLink
                noStyles
                css={`
                  color: white;
                `}
                to="/"
              >
                <picture
                  css={`
                    display: flex;
                    align-items: center;
                  `}
                >
                  <source
                    srcSet={beerFinderLogo}
                    media={`(min-width: ${breakpoint.sm})`}
                  />
                  <Logo
                    css={`
                      cursor: pointer;
                      min-height: 50px;
                    `}
                    srcSet={beerFinderLogo}
                    // src={beerFinderLogoSmall}
                    alt="Beer Finder logo"
                  />
                </picture>
              </InternalLink>
            </Cluster>
          </Stack>
        </Container>
      </Nav>
      <div
        css={`
          position: absolute;
          z-index: -10;
          width: 100%;
          height: 20vh;
          background: ${p => p.theme.colors.primaryLight};

          :after {
            content: '';
            position: absolute;
            bottom: 0;
            width: 100%;
            border-bottom: 40px solid ${p => p.theme.colors.primaryLighter};
          }

          @media (min-width: ${breakpoint.sm}) {
            height: 40vh;
          }
        `}
      />
      <Container
        css={`
          padding-left: 0;
          padding-right: 0;

          @media (min-width: ${breakpoint.sm}) {
            padding-top: 20px;
            padding-bottom: 100px;
          }
        `}
      >
        <Center>
          <ContentBlock>
            <Stack>
              <H
                as="h1"
                css={`
                  padding-bottom: 10px;
                  border-bottom: 4px solid ${p => p.theme.colors.contrast};
                `}
                textColor="primary"
                size={'xl'}
              >
                Blog Posts
              </H>
              <div>
                <Stack
                  css={`
                    > * + * {
                      border-top: 1px solid lightgrey;
                      padding-top: 20px;
                    }
                  `}
                  xl
                >
                  {edges.map(edge => {
                    const {
                      title,
                      path,
                      date,
                      featuredImage,
                      metaDescription,
                    } = edge.node.frontmatter;

                    const featuredImgFluid =
                      featuredImage?.childImageSharp?.fluid;

                    return (
                      <div key={`${path}`}>
                        <Stack xs>
                          {featuredImgFluid && (
                            <div>
                              <InternalLink noStyles to={`/blog/${path}`}>
                                <Img fluid={featuredImgFluid} />
                              </InternalLink>
                            </div>
                          )}
                          <div>
                            <Stack>
                              <Stack xs>
                                <div>
                                  <InternalLink
                                    css={`
                                      font-size: 2rem;
                                      line-height: 2.5rem;
                                      text-decoration: underline;
                                      :hover {
                                        color: ${p => p.theme.colors.primary};
                                      }
                                    `}
                                    noStyles
                                    to={`/blog/${path}`}
                                  >
                                    <strong>{title}</strong>
                                  </InternalLink>
                                </div>
                                <div>
                                  <div>Published: {date}</div>
                                </div>
                              </Stack>
                              <P lg>{metaDescription}</P>
                            </Stack>
                          </div>
                        </Stack>
                      </div>
                    );
                  })}
                </Stack>
              </div>
            </Stack>
          </ContentBlock>
        </Center>
      </Container>
      <Footer />
      <SignupForm />
    </ThemeProvider>
  );
}

export default LayoutPost;

const Logo = styled.img`
  max-width: 150px;
  @media (min-width: ${breakpoint.sm}) {
    max-width: 200px;
  }
`;

const Nav = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  position: sticky;
  top: 0;
  z-index: 10;
  background: ${p => p.theme.colors.black};

  @media (min-width: ${breakpoint.sm}) {
    padding-top: 20px;
    padding-bottom: 20px;
    position: sticky;
  }
`;

const ContentBlock = styled.div`
  max-width: 100%;
  z-index: 10;

  background: white;
  padding: 20px;
  padding-bottom: 80px;

  @media (min-width: 800px) {
    padding: 40px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.18);
    max-width: 640px;
  }
`;
